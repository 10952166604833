import { ReactElement, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { PORTAL_URL } from 'config/config'
import logo from 'assets/logos/logo-D2D-Icodev.svg'
import { HOME_PATH } from 'utils/navRoutes'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

const defaultProps = {
  innerComponent: <div />,
}

export default function TopBar({ appName, innerComponent, hasAccess }: Props): ReactElement {
  const navigate = useNavigate()
  return (
    <div className="topbar">
      <header role="banner" className="sncf-banner">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
        </div>
        <div
          className="app-name-container"
          aria-hidden
          role="button"
          onClick={() => navigate(HOME_PATH)}
        >
          <h1 className="app-name">{appName}</h1>
        </div>
      </header>
      {hasAccess && innerComponent}
      <AccountMenu />
    </div>
  )
}

TopBar.defaultProps = defaultProps
