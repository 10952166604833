import { auth } from '@osrdata/app_core'
import 'App.scss'
import {
  ReactElement, StrictMode, Suspense, useEffect, useState,
} from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import AccessDenied from 'components/AccessDenied/AccessDenied'
import Loader from 'components/Loader/Loader'
import TabList from 'components/TabList/TabList'
import TopBar from 'components/TopBar/TopBar'
import { terms } from 'common/terms'
import navRoutes, { HOME_PATH } from 'utils/navRoutes'
import navTabElements from 'utils/navTabElements'
import { useAppDispatch, useAppSelector } from 'hooks'

const APP_NAME = 'Indicateurs RH'
const ACCESS_PERM = 'indicateurs-rh::ACCESS'

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!appPermissions.includes(ACCESS_PERM)) {
        setAccessDenied(true)
      }
    }
  }, [isLogged, appPermissions])

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <TopBar
            appName={APP_NAME}
            innerComponent={<TabList tabs={navTabElements} />}
            hasAccess={!isLoading && !accessDenied}
          />
          {isLoading && <div id="app"><Loader message={terms.Common.loading} /></div>}
          {!isLoading && (accessDenied ? <AccessDenied /> : (
            <div id="app">
              <Routes>
                {navRoutes.map(route => (
                  <Route element={route.component()} path={route.path} key={route.path} />
                ))}
                <Route path="*" element={<Navigate to={HOME_PATH} />} />
              </Routes>
            </div>
          ))}
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
